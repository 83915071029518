@font-face {
  font-family: "Berkeley Mono";
	src: url("assets/fonts/BerkeleyMonoVariable-Regular.woff2") format("woff2"),
	     url("assets/fonts/BerkeleyMonoVariable-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

::selection {
  background-color: yellow;
}

::-moz-selection {
  background-color: yellow;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  font-family: "Berkeley Mono", monospace;
  font-feature-settings: "ss03" on;
  font-variation-settings: "wght" 100;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

.container {
  padding: 80px 16px;
  max-width: 572px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 16px;
  }
}

h1, h2, ul, p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}

h1,
h2 {
  padding-top: 16px;
}

h2 {
  padding-bottom: 16px;
}

ul {
  list-style: none;
}

ul li {
  display: block;
  position: relative;
  padding-left: 21px;
}

ul li:before {
  content: "•";
  position: absolute;
  left: 6px;
}

ul li ul {
  padding-left: 0;
}

ul li ul li:before {
  content: "";
  top: 6px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px;
}

ul span {
  color: #AAAAAA;
}

a {
  position: relative;
  color: inherit;
  text-decoration: none;
}

a:after {
  content: '';
  position: absolute;
  inset: 0 0 1px;
  box-shadow: 0 1px 0 0 #AAAAAA;
}

a:before {
  content: '';
  z-index: -1;
  position: absolute;
  inset: 0;
  opacity: 0;
  background-color: #F6F6F6;
}

a:hover:before {
  opacity: 1;
}

.tooltipText {
  cursor: help;
  display: inline-block;
  border-bottom: 1px dotted #AAAAAA;
}

.tooltip {
  font-size: 22px;
  background-color: #FFFFFF;
  border: 1px solid #AAAAAA;
  padding: 4px;
  position: fixed;
  z-index: 1;
}
